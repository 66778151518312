<template>
  <div class="verification-wrap">
    <el-form ref="loginForm" :model="loginForm" :rules="loginRules">
      <el-form-item prop="phonenumber">
        <el-input
          ref="phonenumber"
          clearable
          v-model="loginForm.phonenumber"
          placeholder="请输入手机号"
        ></el-input>
      </el-form-item>
      <el-form-item prop="verification">
        <el-input
          placeholder="请输入验证码"
          ref="verification"
          v-model="loginForm.verification"
        >
          <el-button
            class="verification-btn"
            type="primary"
            slot="append"
            :disabled="verifibtn"
            @click="getverificate"
            >{{ vervalue ? `重新获取(${vervalue})` : "获取验证码" }}</el-button
          >
        </el-input>
      </el-form-item>
      <el-form-item prop="password">
        <el-input
          placeholder="请设置密码"
          ref="password"
          clearable
          type="password"
          show-password
          v-model="loginForm.password"
        >
        </el-input>
      </el-form-item>
      <el-form-item prop="confirmpassword">
        <el-input
          placeholder="请确认密码"
          ref="password"
          clearable
          type="password"
          show-password
          v-model="loginForm.confirmpassword"
        >
        </el-input>
      </el-form-item>
      </el-form-item prop="agreement">
        <el-checkbox class="checkagree" v-model="agreement"></el-checkbox>
        <span class="useragree">我已阅读并同意</span>
        <span @click="navUserAgreement" class="protocal">《用户协议》</span>
        <span class="useragree">和</span>
        <span @click="navPrivacyPolicy" class="protocal">《隐私政策》</span>
      </el-form-item>
      <el-button
        :loading="loading"
        class="loginbtn"
        type="primary"
        @click.native.prevent="handleLogin"
        >立即注册</el-button
      >
      <p class="accounttext">
        已有账号？<span @click="navlogin" class="register">点此登录</span>
      </p>
    </el-form>
  </div>
</template>
<script>
import { isPhonenumber } from "../../../utils/validate";
import navigation from "../../../utils/navigation";
import md5 from "js-md5";
import { userAgreement, privacyPolicy } from "@/utils/public"
import { Message } from 'element-ui';
export default {
  data() {
    const validatePhonenumber = (rule, value, callback) => {
      if (!isPhonenumber(value)) {
        callback(new Error("请输入正确的手机号"));
      } else {
        callback();
      }
    };
    const validateVerifica = (rule, value, callback) => {
      if (value.length == 0) {
        callback(new Error("请输入验证码"));
      } else {
        callback();
      }
    };
    const validatePassword = (rule, value, callback) => {
      if (value.length == 0) {
        callback(new Error("请输入密码"));
      } else {
        if (this.loginForm.confirmpassword.length !== 0) {
          this.$refs.loginForm.validateField("confirmpassword");
        }
        callback();
      }
    };
    const confirmPassword = (rule, value, callback) => {
      if (value.length == 0) {
        callback(new Error("请再次输入密码"));
      } else if (value !== this.loginForm.password) {
        callback(new Error("两次输入密码不一致"));
      } else {
        callback();
      }
    };
    return {
      loginForm: {
        phonenumber: "",
        verification: "",
        password: "",
        confirmpassword: "",
      },
      loginRules: {
        phonenumber: [
          { required: true, trigger: "blur", validator: validatePhonenumber },
        ],
        verification: [
          { required: true, trigger: "blur", validator: validateVerifica },
        ],
        password: [
          { required: true, trigger: "blur", validator: validatePassword },
        ],
        confirmpassword: [
          { required: true, trigger: "blur", validator: confirmPassword },
        ],
      },
      loading: false,
      vervalue: "",
      verifibtn: false,
      agreement: false
    };
  },
  props: ["phoneNumber"],
  watch: {
    phoneNumber (val) {
      this.loginForm.phonenumber = val;
      this.setFocus();
    }
  },
  mounted() {
    this.setFocus();
  },
  methods: {
    setFocus() {
      if (this.loginForm.phonenumber === "") {
        this.$refs.phonenumber.focus();
      } else if (this.loginForm.verification === "") {
        this.$refs.verification.focus();
      }
    },
    navlogin() {
      navigation.navigateTo({
        url: "login",
      });
    },
    handleLogin() {
      if (!this.agreement) {
        this.$message.warning('需同意用户协议和隐私政策才能注册');
        return
      }
      this.$refs.loginForm.validate((valid) => {
        console.log(valid);
        if (valid) {
          this.loading = true;
          const data = {
            Type: 1,
            PhoneNumber: this.loginForm.phonenumber,
            VerificationCode: this.loginForm.verification,
            Password: md5(this.loginForm.password),
            Referrer: document.referrer
          };
          console.log("referer: " + document.referrer);
          if (this.$route.query && this.$route.query.InvitationCode) {
            data.InvitationCode = this.$route.query.InvitationCode
          }
          this.$store
            .dispatch("user/userregister", { ...data })
            .then((res) => {
              if (res.RetCode == 0) {
                this.$router.push({
                  path: "/",
                });
              } else if (res.RetCode == 5) {
                this.$message.error("验证码错误");
              } else if (res.RetCode == 6) {
                this.$message.error("该手机号已被注册");
              } else {
                if(res.Message) {
                  this.$message.error(res.Message); 
                }else {
                  this.$message.error("注册失败");  
                }
              }
              this.loading = false;
            })
            .catch(() => {
              this.loading = false;
            });
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    getverificate() {
      const valid = this.$refs.phonenumber.validateState;
      if (valid == "success") {
        this.verifibtn = true;
        var vartime = 60;
        var timer = setInterval(() => {
          vartime--;
          this.vervalue = `${vartime}s`;
          if (vartime == 0) {
            clearInterval(timer);
            this.verifibtn = false;
            this.vervalue = "";
          }
        }, 1000);
        var payload = {
          PhoneNumber: this.loginForm.phonenumber,
        };
        this.$store.dispatch("user/sendvertification", { ...payload });
        // TODO  发送成功后调用
        this.$message({
          showClose: true,
          message: '验证码发送成功',
          showClose: false,
          type: 'success'
        });
      } else {
        return;
      }
    },
    navUserAgreement() {
      window.location.href = userAgreement;
    },
    navPrivacyPolicy() {
      window.location.href = privacyPolicy;
    }
  },
};
</script>
<style lang="scss" scoped>
.verification-wrap {
  margin-top: 35px;
  .el-form-item {
    margin-bottom: 30px;
  }
  ::v-deep .el-input__inner {
    border-radius: 5px;
  }
  ::v-deep .el-input-group__append {
    border: none;
  }
  .loginbtn {
    font-size: 16px;
    width: 100%;
    border-radius: 5px;
    background: rgba(88, 151, 242, 1);
  }
  .verification-btn {
    width: 110px;
    border-radius: 5px;
    border: none;
    color: #fff;
    background: rgba(88, 151, 242, 1);
  }
  .checkagree {
    margin-bottom: 10px;
    color: rgba(61, 61, 61, 1);
  }
  .useragree {
    margin-left: 5px;
    font-size: 12px;
  }
  .protocal {
    font-size: 12px;
    margin-left: 2px;
    color: rgba(74, 99, 240, 0.93);
    cursor: pointer;
  }

  .accounttext {
    font-size: 14px;
    text-align: center;
    margin-top: 20px;
    .register {
      color: rgba(74, 99, 240, 0.93);
      cursor: pointer;
    }
  }
}
</style>