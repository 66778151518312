import router from '../router'

export default {
    navigateTo: ({
        url,
        params
    }) => {
        if (params) {
            router.push({
                name: url,
                params: params
            })
        } else {
            router.push({
                name: url
            })
        }
    },
    back: () => {
        router.back()
    },
    redirectTo: ({
        url,
        params
    }) => {
        if (params) {
            router.replace({
                name: url,
                params: params
            })
        } else {
            router.replace({
                name: url
            })
        }
    }

}